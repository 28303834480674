/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    h3: "h3",
    code: "code",
    hr: "hr"
  }, _provideComponents(), props.components), {Type, ParamTable, Params} = _components;
  if (!ParamTable) _missingMdxReference("ParamTable", true);
  if (!Params) _missingMdxReference("Params", true);
  if (!Type) _missingMdxReference("Type", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "概要",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E6%A6%82%E8%A6%81",
    "aria-label": "概要 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), "概要"), "\n", React.createElement(_components.p, null, "Controllerコンポーネントはコントロールパターンをインデックスに変換し、Moveコンポーネントに伝えます。また、インデックス関係の様々なメソッドを提供します。"), "\n", React.createElement(_components.h2, {
    id: "メソッド",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E3%83%A1%E3%82%BD%E3%83%83%E3%83%89",
    "aria-label": "メソッド permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), "メソッド"), "\n", React.createElement(_components.h3, {
    id: "go",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#go",
    "aria-label": "go permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "go()")), "\n", React.createElement(Type, {
    code: 'go( control: number | string, allowSameIndex?: boolean, callback?: AnyFunction ): void'
  }), "\n", React.createElement(_components.p, null, "以下のコントロールパターンにより、スライダーを動かします。", React.createElement(_components.code, null, "Splide#go()"), "はこのメソッドのエイリアスです。"), "\n", React.createElement(ParamTable, {
    params: [['i', 'スライド`i`に移動する'], [`'+\${i}'`, 'スライドインデックスを`i`増加させる'], [`'-\${i}'`, 'スライドインデックスを`i`減少させる'], [`'>'`, '次のページに移動する'], [`'<'`, '前のページに移動する'], [`'>\${i}'`, 'ページ`i`に移動する']]
  }), "\n", React.createElement(Params, {
    params: [['control', '上記コントロールパターンのいずれか'], ['allowSameIndex', '省略可。現在のインデックスと同一のインデックスを受け付けるかどうか'], ['callback', '省略可。移動が終わった直後に呼ばれるコールバック関数']]
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "scroll",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#scroll",
    "aria-label": "scroll permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "scroll()")), "\n", React.createElement(Type, {
    code: 'scroll( destination: number, duration?: number, snap?: boolean, callback?: AnyFunction ): void'
  }), "\n", React.createElement(_components.p, null, "スライダーを特定の位置やインデックスにスクロールさせます。このとき、現在のインデックスと一つ前のインデックスを更新します。この関数は、CSSのトランジションではなく、Scrollコンポーネントを利用してスライダーを動かします。"), "\n", React.createElement(Params, {
    params: [['destination', '目的地となるポジションまたはインデックス。インデックスを使用する際は、`useIndex`を`true`にする必要がある'], ['duration', '省略可。スクロールにかける時間を設定する'], ['snap', '省略可。目的地を、最も近いスライドにスナップするかどうかを決定する'], ['callback', '省略可。スクロールが終わった直後に呼ばれるコールバック関数']]
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "getnext",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#getnext",
    "aria-label": "getnext permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "getNext()")), "\n", React.createElement(Type, {
    code: 'getNext( destination?: boolean ): number'
  }), "\n", React.createElement(_components.p, null, "次の行き先となるスライドのインデックスを返します。このメソッドはデフォルトで、クローンのインデックスを実際のスライドのインデックスに変換します。たとえば、目的となるインデックスが", React.createElement(_components.code, null, "-1"), "（クローン）の場合であっても", React.createElement(_components.code, null, "length - 1"), "として計算した実際のスライドのインデックスを返します。", React.createElement(_components.code, null, "destination"), "引数を", React.createElement(_components.code, null, "true"), "に設定すると、この変換をせず、そのままの値を返すようになります。"), "\n", React.createElement(_components.p, null, "ただし、行き先がなかった場合、このメソッドは常に", React.createElement(_components.code, null, "-1"), "を返します。"), "\n", React.createElement(Params, {
    params: [['destination', '省略可。目的地のインデックスをそのまま返すかどうかを決定する']],
    ret: '次のスライドのインデックス。これ以上行き先がなかった場合は`-1`'
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "getprev",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#getprev",
    "aria-label": "getprev permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "getPrev()")), "\n", React.createElement(Type, {
    code: 'getPrev( destination?: boolean ): number'
  }), "\n", React.createElement(_components.p, null, "戻り先となるスライドのインデックスを返します。このメソッドはデフォルトで、クローンのインデックスを実際のスライドのインデックスに変換します。たとえば、目的となるインデックスが", React.createElement(_components.code, null, "-1"), "（クローン）の場合であっても", React.createElement(_components.code, null, "length - 1"), "として計算した実際のスライドのインデックスを返します。", React.createElement(_components.code, null, "destination"), "引数を", React.createElement(_components.code, null, "true"), "に設定すると、この変換をせず、そのままの値を返すようになります。"), "\n", React.createElement(_components.p, null, "ただし、戻り先がなかった場合、このメソッドは常に", React.createElement(_components.code, null, "-1"), "を返します。"), "\n", React.createElement(Params, {
    params: [['destination', '省略可。目的地のインデックスをそのまま返すかどうかを決定する']],
    ret: '戻り先のスライドのインデックス。これ以上戻る場所がなかった場合は`-1`'
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "getend",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#getend",
    "aria-label": "getend permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "getEnd()")), "\n", React.createElement(Type, {
    code: 'getEnd(): number'
  }), "\n", React.createElement(_components.p, null, "終わりのインデックスを返します。これは、「スライドの枚数 - 1」とは必ずしも一致しません。たとえば、全体数が10で", React.createElement(_components.code, null, "perPage"), "が", React.createElement(_components.code, null, "3"), "の場合、スライダーはインデックス7までしか到達できませんので、この関数は", React.createElement(_components.code, null, "7"), "を返します。"), "\n", React.createElement(Params, {
    ret: '終わりのインデックス'
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "setindex",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#setindex",
    "aria-label": "setindex permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "setIndex()")), "\n", React.createElement(Type, {
    code: 'setIndex( index: number ): void'
  }), "\n", React.createElement(_components.p, null, "現在のインデックスを更新します。このメソッドは、単に内部的な変数を更新するのみで、スライダーは移動しません。"), "\n", React.createElement(Params, {
    ret: '新しくセットするインデックス'
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "getindex",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#getindex",
    "aria-label": "getindex permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "getIndex()")), "\n", React.createElement(Type, {
    code: 'getPrev( prev?: boolean ): number'
  }), "\n", React.createElement(_components.p, null, "現在のインデックスを返します。", React.createElement(_components.code, null, "Splide#index"), "はこの関数のエイリアスです。なお、", React.createElement(_components.code, null, "prev"), "を", React.createElement(_components.code, null, "true"), "にすると、一つ前の古いインデックスを返します。"), "\n", React.createElement(Params, {
    params: [['prev', '省略可。一つ前の古いインデックスを取得するかどうかを決定']]
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "toindex",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#toindex",
    "aria-label": "toindex permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "toIndex()")), "\n", React.createElement(Type, {
    code: 'toIndex( page: number ): number'
  }), "\n", React.createElement(_components.p, null, "与えられたページインデックスをスライドインデックスに変換します。", React.createElement(_components.code, null, "perPage"), "が1より大きい場合にのみ意味のある関数です。"), "\n", React.createElement(Params, {
    params: [['page', '変換するページインデックス']],
    ret: '与えられたページに対応するスライドインデックス'
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "topage",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#topage",
    "aria-label": "topage permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "toPage()")), "\n", React.createElement(Type, {
    code: 'toPage( index: number ): number'
  }), "\n", React.createElement(_components.p, null, "与えられたスライドインデックスをページインデックスに変換します。", React.createElement(_components.code, null, "perPage"), "が1より大きい場合にのみ意味のある関数です。"), "\n", React.createElement(Params, {
    params: [['page', '変換するスライドインデックス']],
    ret: '与えられたスライドに対応するページインデックス'
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "isbusy",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#isbusy",
    "aria-label": "isbusy permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "isBusy()")), "\n", React.createElement(Type, {
    code: 'isBusy(): boolean'
  }), "\n", React.createElement(_components.p, null, "スライダーが現在移動可能かどうかを確認します。"), "\n", React.createElement(Params, {
    ret: '移動可能な場合は`true`を、そうでない場合は`false`を返す'
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
